.MuiMenuItem-root {
  font-size: 12px !important;
}

.jss38 {
  width: 271px !important;
}

.jss40 {
  width: 282px !important;
}

.jss52 {
  font-size: 15px !important;
  font-weight: bold !important;
}
.jss53 a {
  padding-left: 0px;
}

.jss49 a {
  padding-left: 16px !important;
}

.RaSidebar-drawerPaper-38 {
  width: 270px !important;
}

.RaTreeMenu-open-40 {
  width: 282px !important;
}

.RaTreeCustomMenuItem-menuItemName-52 {
  font-size: 15px !important;
  font-weight: bold !important;
}

.RaTreeCustomMenuItem-sidebarIsOpen-49 a {
  padding-left: 16px !important;
}

